import React, { useEffect, useRef, useState } from 'react'
import './Achievments.css'
import { motion, useMotionValue, useTransform, animate, useInView } from 'framer-motion';

const Achievments = () => {
    const ref = useRef(null)
    const isInView = useInView(ref)
    const [count1, setCount1] = useState(0)
    const [count2, setCount2] = useState(0)
    const [count3, setCount3] = useState(0)
    const [animated, setAnimated] = useState(false)

    useEffect(() => {
        numberAnimate(setCount1,100)
        numberAnimate(setCount2,7)
        numberAnimate(setCount3,1000)
      }, [isInView]);

    const numberAnimate = (setCount,end) =>{
        if(isInView && !animated){
            setAnimated(true)
            const duration = 1.5
            const controls = animate(0, end, {
                duration,
                onUpdate: value => setCount(Math.round(value)),
              });
          
              return () => controls.stop();
        }
    }
      
  return (
    <div style={{backgroundColor: '#4582ff'}}>
        <div className="d-flex flex-column container pb-5 pt-5 text-white">
            <div className="text-center pb-2">
                <span className="heading fw-bold fs-1">Achievments</span>
            </div>
            <div className="d-flex flex-md-row flex-column justify-content-center" style={{gap: "20%",whiteSpace: 'nowrap'}}>
                <div className="d-flex flex-column text-center pb-4">
                    <motion.span ref={ref} className="fw-bold" style={{fontSize: "3rem",color: '#FFD700',fontFamily: 'monospace'}}>{`${count1}+`}</motion.span>
                    <span className="fs-5">5 Star Google Reviews</span>
                </div>
                <div className="d-flex flex-column text-center pb-4">
                    <span className="fw-bold" style={{fontSize: "3rem",color: '#FFD700',fontFamily: 'monospace'}}>{`${count2}+`}</span>
                    <span className="fs-5">Years in Business</span>
                </div>
                <div className="d-flex flex-column text-center pb-4">
                    <span className="fw-bold" style={{fontSize: "3rem",color: '#FFD700',fontFamily: 'monospace'}}>{`${count3}+`}</span>
                    <span className="fs-5">Customers</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Achievments