import React, { useState } from 'react'
import {WhatsApp,Menu} from '@mui/icons-material';
import { Drawer, List, ListItem, ListItemText } from '@mui/material';
import './Header.css'
import gletter from '../../assets/icons/gstmaster-G_2.png'

const Header = () => {
    const [open, setOpen] = useState(false)
  return (
    <>
    <div className="d-flex justify-content-between align-items-center p-3">
        <div className='fw-bold fs-4 d-flex'>
            <span><img src={gletter} className='pb-1' style={{width:'18px',paddingRight:'0.4px'}}/></span>
            STmaster
        </div>
        {/* <div className="d-none d-lg-flex">
            <ul className="d-flex gap-4 cursor-pointer m-0">
                <li className="active">Home</li>
                <li>File GST Return</li>
                <li>Apply New GST</li>
                <li>Cancel GST</li>
                <li>Change GST Details</li>
            </ul>
        </div> */}
        <div className="d-flex align-items-center">
            <WhatsApp style={{fontSize: '2rem',color: 'green'}}/>
            <span className="ps-1">+91 866 72 91 570</span>
        </div>
        {/* <div className="d-lg-none d-flex">
            <Menu onClick={()=>setOpen(true)}/>
        </div> */}
    </div>
    <Drawer className='drawer' anchor="right" open={open} onClose={() => setOpen(false)}
        PaperProps={{
          sx: {
            width: 240,
            alignItems:'center',
            justifyContent:"center"
          }
        }}
      >
        <List>
          <ListItem>
            {/* <Link className='text-decoration-none'> */}
              <ListItemText primary="Home" className='text-black' onClick={() => setOpen(!open)} />
            {/* </Link> */}
          </ListItem>
          <ListItem>
            {/* <Link className='text-decoration-none'> */}
              <ListItemText primary="File GST Return" className='text-black' onClick={() => setOpen(!open)}/>
            {/* </Link> */}
          </ListItem>
          <ListItem>
            {/* <Link className='text-decoration-none'> */}
              <ListItemText primary="Apply New GST" className='text-black' onClick={() => setOpen(!open)}/>
            {/* </Link> */}
          </ListItem>
          <ListItem button>
          {/* <Link className='text-decoration-none'> */}
              <ListItemText primary="Cancel GST" className='text-black' onClick={() => setOpen(!open)}/>
            {/* </Link> */}
          </ListItem>
          <ListItem button>
          {/* <Link className='text-decoration-none'> */}
              <ListItemText primary="Change GST Details" className='text-black' onClick={() => setOpen(!open)}/>
            {/* </Link> */}
          </ListItem>
        </List>
      </Drawer>
    </>
  )
}

export default Header