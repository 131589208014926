import React from 'react'
import Header from '../Header/Header'
import './Home.css'
import Banner from '../Banner/Banner'
import ChooseUs from '../ChooseUs/ChooseUs'
import Reviews from '../Reviews/Reviews'
import Achievments from '../Achievments/Achievments'
import Footer from '../Footer/Footer'

const Home = () => {
  return (
    <div>
        <Header/>
        <Banner/>
        <ChooseUs/>
        <Reviews/>
        <Achievments/>
        <Footer/>
    </div>
  )
}

export default Home