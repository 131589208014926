import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNfcSymbol } from '@fortawesome/free-brands-svg-icons';
import { VisibilityOff } from '@mui/icons-material';
import { faUserShield, faUsers } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion'

const ChooseUs = () => {
  return (
    <div className="d-flex align-items-center" style={{height: '20rem',backgroundColor: 'rgba(69,130,255)'}}>
        <div className="container py-4 d-flex flex-column gap-4 text-white">
            <div className="d-flex justify-content-center">
                <motion.span 
                initial={{opacity:0,transform:'translateY(30px)'}}
                whileInView={{opacity:1,transform:'translateY(0px)'}}
                transition={{duration:2,type:'spring'}}
                viewport={{once:true}}
                className="heading fw-bold fs-3"
                >
                  Why Choose Us
                </motion.span>
            </div>
            <div className="row row-gap-3 w-100 justify-content-center text-center">
              <div className='col-lg-3 col-md-6 d-flex align-items-center justify-content-center text-center'>
                <span><FontAwesomeIcon className='fs-4 pe-2 text-warning' icon={faNfcSymbol} /></span>
                <span className="fs-5 fw-semibold">Complete Transparency</span>
              </div> 
              <div className='col-lg-3 col-md-6 d-flex align-items-center justify-content-center text-nowrap text-center'>
                <span className='pe-2 text-warning'><VisibilityOff className='fs-3'/></span>
                <span className="fs-5 fw-semibold col-lg-3 col-md-6">No hidden charges</span>
              </div>
              <div className='col-lg-3 col-md-6 d-flex align-items-center text-nowrap justify-content-center'>
                <span><FontAwesomeIcon className='pe-2 fs-4 text-warning' icon={faUserShield} /></span>
                <span className="fs-5 fw-semibold col-lg-3 col-md-6">Backed by experts</span>
              </div>
              <div className='col-lg-3 col-md-6 d-flex align-items-center justify-content-center text-nowrap'>
                <span><FontAwesomeIcon className='pe-2 fs-4 text-warning' icon={faUsers} /></span>
                <span className="fs-5 fw-semibold col-lg-3 col-md-6">Trusted by customers</span>
              </div>
            </div>
        </div>
    </div>
  )
}

export default ChooseUs