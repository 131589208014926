import React from 'react'
import { WhatsApp } from '@mui/icons-material'
import './Footer.css'

const Footer = () => {
    const handleWhatsAppClick = () => {
        const whatsappNumber = "8667291570";
        window.open(`https://api.whatsapp.com/send?phone=${whatsappNumber}`, '_blank');
      };
  return (
    <div>
        <div className="container py-5 px-3">
            <div className="row row-gap-5">
                <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="pb-3">
                        <span className="heading fw-bold fs-4">GSTmaster</span>
                    </div>
                    <div>
                        <span>GST, One Nation One Tax system created a Taxation history on July 1st, 2017. Albeit making Businessmen to stop evading taxes, post – GST era has created inconvenience for them.</span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="pb-3">
                        <span className="heading fw-bold fs-4">Quick Links</span>
                    </div>     
                    <div className="d-flex flex-column gap-1">
                        <span>GST</span>
                        <span>Income Tax</span>
                        <span>GST Registration</span>
                        <span>GST Filing</span>
                        <span>Terms of Service</span>
                        <span>Privacy Policy</span>
                        <span>Refund Policy</span>
                        <span>Disclaimer</span>
                        <span>Sitemap</span>
                    </div>           
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="pb-3">
                        <span className="heading fw-bold fs-4">Get in Touch</span>
                    </div>
                    <div className="d-flex flex-column gap-1">
                        <span><span className="fw-bold">E-mail:</span>gstmaster1@gmail.com</span>
                        <span><span className="fw-bold">Phone:</span> +91 866 72 91 570</span>
                        <span><span className="fw-bold">Address:</span> 31,32, basheera nagar,vallam,thanjavur,613403</span>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="pb-3">
                        <span className="heading fw-bold fs-4">Get in Touch</span>
                    </div>
                    <div>
                        <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d245.0064547260885!2d79.05624506839628!3d10.726517322967453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTDCsDQzJzM1LjciTiA3OcKwMDMnMjIuNCJF!5e0!3m2!1sen!2sin!4v1704692539679!5m2!1sen!2sin" 
                        width="300" 
                        height="250" 
                        style={{border:0}} 
                        allowfullscreen="" 
                        loading="lazy" 
                        referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
        <div 
        className="position-fixed bottom-0 end-0 me-md-5 me-2 mb-3 z-3 bg-white p-2 rounded-2 border border-success"
        onClick={()=>handleWhatsAppClick()}
        >
            <WhatsApp className="text-success" style={{fontSize:'2rem'}}/>
        </div>
    </div>
  )
}

export default Footer