import React from 'react'
// import './Reviews.css'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {ArrowBackIos,ArrowForwardIos} from '@mui/icons-material';
import { motion } from 'framer-motion'

const Reviews = () => {
  const reviews = [
    {
      review : 'We have worked with Riyaz of Best Labz for last 2+ years and he has delivered more than 10 projects for us. We are happy with the quality of work they deliver and looking forward to more years of working with them.',
      name : 'Balavishnu',
      postion : 'CEO of Techno Tackle'
    },
    {
      review : 'We have worked with Riyaz of Best Labz for last 2+ years and he has delivered more than 10 projects for us. We are happy with the quality of work they deliver and looking forward to more years of working with them.',
      name : 'Balavishnu',
      postion : 'CEO of Techno Tackle'
    },
    {
      review : 'We have worked with Riyaz of Best Labz for last 2+ years and he has delivered more than 10 projects for us. We are happy with the quality of work they deliver and looking forward to more years of working with them.',
      name : 'Balavishnu',
      postion : 'CEO of Techno Tackle'
    }
  ]
  return (
    <>
    <div className="py-5 container">
      <div className="text-center container pb-4">
        <motion.span 
        className="heading fs-3 fw-bold"
        initial={{opacity:0}}
        whileInView={{opacity:1}}
        transition={{duration:3,type:'spring'}}
        viewport={{once:true}}
        >
          Here's What Our Customers have to Say
        </motion.span>
      </div>
      <div className="d-flex flex-md-row flex-column gap-3">
        {
          reviews.map((review)=>(
            <div className="p-3 d-flex flex-column text-center gap-2" style={{boxShadow: "rgba(69,130,255, 0.2) 0px 8px 24px"}}>
              <span className="">{review.review}</span>
              <span className="fw-bold">{review.name}</span>
              <span className="fw-medium">{review.postion}</span>
            </div>
          ))
        }
      </div>
    </div>
    {/* <div className='review_container my-5'>
      <div className='text-center h2'>
        <span className='reviews_heading fw-bold'>Here's What Our Customers have to Say</span>
      </div>
      <Carousel
      showStatus={false}
      autoPlay={true}
      infiniteLoop={true}
      interval={5000}
      transitionEffect="carousel"
      showIndicators={false}
      renderArrowNext={(clickHandler) => (
        <div
          className='position-absolute top-0 bottom-0 end-0 d-flex justify-content-center align-items-center pe-sm-3'
          onClick={clickHandler}
        >
          <ArrowForwardIos/>
        </div>
      )}
      renderArrowPrev={(clickHandler) => (
          <div
            className='position-absolute top-0 bottom-0 start-0 d-flex align-items-center justify-content-center z-1 ps-sm-3'
            onClick={clickHandler}
          >
            <ArrowBackIos/>
          </div>
        )}
      >
        {
          reviews.map((review)=>(
            <div className='review_slider d-flex flex-column gap-4 p-5'>
              <span className='fs-6 fw-medium'>{review.review}</span>
              <div className='review_details d-flex flex-column'>
                <span className='fs-5 fw-medium'>{review.name}</span>
                <span className=''>{review.postion}</span>
              </div>
            </div>
          ))
        }
      </Carousel>
    </div> */}
    </>
  )
}

export default Reviews