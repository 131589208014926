import React from 'react'
import gstImage from '../../assets/images/1702959863531.jpg'
import './Banner.css'
import {motion} from 'framer-motion'

const Banner = () => {
    const variants = {
        initial: { 
            transform: 'transformY(30px)', 
            opacity: '0'
        },
        whileInView: {
            transform: 'transformY(0px)', 
            opacity: '1',
            transition: {
                duration: 5,
                type: 'spring'
            },
        },
        // transition: {
        //     duration: 5,
        //     type: 'spring'
        // },
        // viewPort: {
        //     once: false
        // }
    }
  return (
    <div className="container px-3 d-flex align-items-center" style={{padding: '5% 0'}}>
        <div className="row row-gap-5">
            <div className="col-lg-6 col-md-12 pt-md-5 p-3 order-lg-1 order-md-2 order-2">
                <div className="row row-gap-5">
                    <div className="d-flex flex-column col-lg-6 ps-md-5 pb-3 pt-3" style={{boxShadow: "rgba(69,130,255, 0.2) 0px 8px 24px"}}>
                        <span className="heading fw-bold fs-4 pb-2">File GST Return</span>
                        <span className="fs-5">File GSTR-3B & GSTR-1 GST Returns monthly. Avoid GST Penalty</span>
                    </div>
                    <div className="d-flex flex-column col-lg-6 ps-md-5 pb-3 pt-3" style={{boxShadow: "rgba(69,130,255, 0.2) 0px 8px 24px"}}>
                        <span className="heading fw-bold fs-4 pb-2">Apply New GST</span>
                        <span className="fs-5">Apply for a new GST number. <span className="fw-semibold" style={{color: '#4582ff'}}>Affordable Price</span>. No hidden charges.</span>
                    </div>
                    <div className="d-flex flex-column col-lg-6 ps-md-5 pb-3 pt-3" style={{boxShadow: "rgba(69,130,255, 0.2) 0px 8px 24px"}}>
                        <span className="heading fw-bold fs-4 pb-2">Cancel GST</span>
                        <span className="fs-5">Cancel your GST number if you no longer need it.</span>
                    </div>
                    <div className="d-flex flex-column col-lg-6 ps-md-5 pb-3 pt-3" style={{boxShadow: "rgba(69,130,255, 0.2) 0px 8px 24px"}}>
                        <span className="heading fw-bold fs-4 pb-2">Change GST Details</span>
                        <span className="fs-5">Change GST email id, password, address, mobile number, trade name.</span>
                    </div>
                    <motion.div 
                    initial={{opacity:0,transform: 'translateY(30px)'}}
                    whileInView={{opacity:1,transform: 'translateY(0px)'}}
                    transition={{duration:1,type:"spring"}}
                    viewport={{once: true}}
                    className="d-flex flex-column col-lg-12">
                        <span className="content fw-semibold fs-5">
                            Call us on 
                            <span className="fw-bold ps-1" style={{color: '#4582ff'}}>
                                866 72 91 570
                            </span> if you need any other help with your GST!
                        </span>
                    </motion.div>
                </div>
            </div>
            <motion.div 
            initial={{opacity:0}} 
            whileInView={{opacity:1}} 
            transition={{duration:1,type:"spring"}} 
            viewport={{once: true}}
            className="col-lg-6 col-md-12 order-lg-2 order-md-1 order-1 d-flex justify-content-center align-items-center"
            >
                {/* <span className="fw-semibold" style={{fontSize: '4rem'}}>GST <br/> Master</span> */}
                <img src={gstImage} className='img-fluid' alt=""/>
            </motion.div>
        </div>
    </div>
  )
}

export default Banner